.return {
	position: absolute;
	display: flex;
	flex-direction: column;
	top: var(--startiapp-inset-top, 32px);
	left: 20px;
	gap: 2.6px;
	background: none;
	border: none;
	outline: none;
	animation: fadeFromLeft 0.6s !important;
	width: 32px;
	height: 32px;
	justify-content: center;
}

@keyframes fadeFromLeft {
	0% {
		transform: translateX(-50px);
		opacity: 0;
	}

	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

.return > * {
	width: 15px;
	height: 2px;
	background: white;
}

.return > *:nth-child(1) {
	transform: rotate(-225deg);
	animation: child1 0.5s;
}

.return > *:nth-child(2) {
	transform: translateX(3px);
	width: 20px;
	animation: child2 0.5s;
}

.return > *:nth-child(3) {
	transform: rotate(225deg);
	animation: child3 0.5s;
}

@keyframes child1 {
	0% {
		transform: rotate(45deg);
	}
}

@keyframes child2 {
	0% {
		width: 0;
	}
}

@keyframes child3 {
	0% {
		transform: rotate(-45deg);
	}
}
