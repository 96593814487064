:root {
  --background-color: #f7931d;
  --foreground-color: #ffffff;
  --valid-color: #ffffff;
  --invalid-color: ;
  --active-color: #ea8816;
  --selection-color: #ffffff40;
}

* {
  box-sizing: border-box;
}

body {
  touch-action: pan-y;
  position: relative;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: var(--background-color);
  color: var(--foreground-color);
  font-family: "Al Bayan Bold", Arial, Helvetica, sans-serif;

  padding-top: var(--startiapp-inset-top, 0px);
  padding-right: var(--startiapp-inset-right, 0px);
  padding-bottom: var(--startiapp-inset-bottom, 0px);
  padding-left: var(--startiapp-inset-left, 0px);
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes fadeIn {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeFromRight {
  0% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
