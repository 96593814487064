.popover {
  z-index: 10;
  position: fixed;
  opacity: 0;
  top: 100%;
  pointer-events: none;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(30px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 40px;
  transition: 0.5s;
}

.popover p {
  font-size: 20px;
}

.popover button {
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--active-color);
  border-radius: 12px;
  width: 60%;
  max-width: 800px;
  height: 60px;
  border: none;
  outline: none;
  color: var(--foreground-color);
  font-size: 16px;
  transition: 0.25s;
}

.popover-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: 0.25s;
  animation: fadeIn 0.5s ease-out;
  max-height: 200px;
}

.open {
  opacity: 1;
  top: 0;
  pointer-events: all;
}

.closed {
  opacity: 0;
  top: 100%;
  pointer-events: none;
}

@media screen and (min-width: 600px) {
  .popover button {
    width: 100%;
  }
}
