.switchStyle {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    max-width: 800px;
    height: 100%;
    max-height: 500px;
    border: 1px solid var(--foreground-color);
    border-radius: 20px;
    transition: .25s;
    animation: fadeIn .5s ease-out;
}

.switchStyle button {
    z-index: 1;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    outline: none;
    color: var(--foreground-color);
    font-size: 16px;
    transition: .25s;
}

.switchStyle button img {
    width: 60px;
    pointer-events: none;
    transition: .25s;
}

.switchStyle hr {
    color: transparent;
    height: 0;
    width: 100%;
    border: none;
    border-top: 1px solid var(--foreground-color);
    border-left: 1px solid var(--foreground-color);
    margin: 0;
    padding: 0;
    opacity: 1;
}

content:focus-within .switchStyle button {
    display: none;
}

@media screen and (min-width: 600px) {
    .switchStyle {
        min-height: 124px;
        width: 100%;
        height: 50%;
        max-height: 250px;
        flex-direction: row;
    }

    .switchStyle button {
        height: 100%;
    }

    .switchStyle hr {
        height: 100%;
        width: 0;
    }

    .switchStyle input {
        width: 50%;
        height: 100%;
    }
    .infoButton {
        width: 100%;
    }
}

@media screen and (max-width: 600px) and (max-height: 600px) {
    .switchStyle button {
        font-size: 14px;
    }

    .switchStyle button img {
        width: 40px;
    }
}



@keyframes fadeInFromLeft {
    0% {
        transform: translateX(-50px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}