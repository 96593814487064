.inputContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    width: 100%;
    max-width: 800px;
    animation: fadeIn .6s;
    transition: .25s;
}

.inputContainer button {
    width: 90%;
    height: 50px;
    background: var(--foreground-color);
    outline: none;
    border: none;
    border-radius: 10px;
    color: var(--background-color);
    transition: .25s;
}

.inputContainer button:disabled {
    width: 45%;
    background: none;
    border: 1px solid var(--foreground-color);
    opacity: .5;
    transition: .5s;
    color: var(--foreground-color);
}

.inputWrapper img {
    height: 100%;
    width: 34px;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    opacity: 0;
    margin-left: 5%;
    transition: .5s;
}

.inputWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    overflow: hidden;
    transition: .25s;
}

.inputWrapper:focus-within {
    width: 100%;
}

.inputWrapper:focus-within img {
    position: absolute;
    opacity: 1;
    margin: 0;
    margin-left: 8px;
}

.inputWrapper input {
    height: 50px;
    width: 100%;
    background: none;
    border: 1px solid var(--valid-color);
    outline: none;
    border-radius: 10px;
    padding-inline: 20px;
    transition: .5s;
    color: var(--foreground-color);
    caret-color: var(--foreground-color);
}

.inputWrapper input:invalid {
    border: 1px solid var(--invalid-color);
}

.inputWrapper input:focus:valid {
    border: 1px solid var(--background-color);
}

.inputWrapper input:focus {
    width: 100%;
    border: 1px solid var(--background-color);
    background: var(--active-color);
    padding-inline: 45px 20px;
}

.inputWrapper input::selection {
    background: var(--selection-color);
}

.inputWrapper input::placeholder {
    opacity: 1;
    color: var(--foreground-color);
    text-align: center;
    transition: .25s;
}

.inputWrapper input:focus::placeholder {
    opacity: 0;
}